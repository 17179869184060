
import React from 'react';
import '../css/Home.css'
import livaImage from '../images/image copy.png';
import livaFullImage from '../images/image copy 2.png';
import arrowsvg from '../images/arrow.svg'
const Home = () => {
  return (
    <>
      <div className="container2">

        <div className="hero">
          <div className="main-text">
            Precision

            <span className='hero-highlight-text'>Engineering Solutions </span>
            for Your Success
          </div>
          <div className="subtitle-text">
            Innovating solutions, addressing problems, crafting meaningful experiences, meeting end-users' needs, aligning with product context. Welcome to DENVIL, where innovation meets excellence.
          </div>
          <div className="hero-button">
            <button className='lets-talk'> Let's Talk</button>
            <button className='learn-more'> Learn More</button>
          </div>
        </div>

        {/* <div class="hero-fix-bottom cc-home"></div> */}

        {/* <div className="about-denvil">
          <img src={livaFullImage}></img>
        </div> */}
        {/* <div className="last-project">
          <span>Last Project completed</span>
        </div> */}
        <div className="liva-project">
         <div class="circle_bg3"></div>
          <div className="left">
            <div className="liva-title">
             <span>Liva Raillings</span> 
            </div>
            <div className="liva-desc">
              At Denvil, we specialize in providing high-quality 3D modeling and design services for a variety of industries, including mechanical engineering, investment casting, plastic molding, and more.
            </div>
            <div className="tags">
              <div className="tag-item">
                3d Rendering
              </div>
              <div className="tag-item">
                3d Models
              </div>
              <div className="tag-item">
                Catalog Design
              </div>

            </div>
          <div className="view-more-btn">
            <button>View more <img src={arrowsvg} alt="My SVG" /></button>
           
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z"/></svg> */}
          </div>
          </div>
          <div className="right">
            <img src={livaImage}></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
