import './App.css';
import Navbar from './pages/Navbar';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div>
 <Router>
      <>
        <Navbar />
        <Routes>
          <Route path="/" element={<><Home /></> } />
        </Routes>
      </>
    </Router>
    </div>
  );
}

export default App;
